import { Backdrop } from '@mui/material';
import { createPortal } from 'react-dom';
import Lottie from 'react-lottie-player';

import animation from './loading.json';
import styles from './portal-backdrop.module.css';

enum BackdropType {
  loading = 'loading',
  app_init = 'app_init',
  module_init = 'module_init',
}

/* eslint-disable-next-line */
export interface PortalBackdropProps {
  type: keyof typeof BackdropType;
  open?: boolean;
}

export function PortalBackdrop({ type, open = true }: PortalBackdropProps) {
  const lottie = (
    <div className={styles['loading']}>
      <Lottie animationData={animation} play />
    </div>
  );
  return type === 'module_init'
    ? open && (
        <div className="flex h-full w-full items-center justify-center">
          {lottie}
        </div>
      )
    : createPortal(
        <Backdrop
          open={open}
          className={styles['overwrite']}
          invisible={type === 'app_init'}
        >
          {lottie}
        </Backdrop>,
        document.body
      );
}

export default PortalBackdrop;
