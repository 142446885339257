import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import {
  getAccessToken,
  isOidcServiceWorkerProxyActive,
  lockForTokenRefresh,
  tokenRefreshMutex,
} from '@vs/oidc-client';
import { DocumentNode } from 'graphql';
import { ClientError } from 'graphql-request';
import type { OidcClientSettings } from 'oidc-client-ts';

export type ReferenceOfGraphqlMeta = {
  _baseUrl: string;
  _getTokenFunc: () => MaybePromise<string | null>;
  _oidcConfig?: OidcClientSettings;
};

type ExtraOptions = Partial<Pick<ClientError, 'request' | 'response'>>;

type DynamicGraphBaseQueryFn = BaseQueryFn<
  { document: string | DocumentNode; variables?: any },
  unknown,
  unknown,
  ExtraOptions
>;
export const dynamicGraphqlBaseQuery =
  (referenceOfMeta: ReferenceOfGraphqlMeta): DynamicGraphBaseQueryFn =>
  async (queryArgs, baseQueryApi, extraOptions = {}) => {
    const { _baseUrl, _oidcConfig, _getTokenFunc } = referenceOfMeta;

    const bs = graphqlRequestBaseQuery({
      url: _baseUrl || '',
      prepareHeaders: async (headers, api) => {
        await tokenRefreshMutex.waitForUnlock();
        if (isOidcServiceWorkerProxyActive() && _oidcConfig) {
          const token = await getAccessToken({
            authority: _oidcConfig.authority,
            client_id: _oidcConfig.client_id,
          });
          /* In service worker mode, token in worker thread will vanish
            due to the browser's memory release. If token is not exist,
            do silent re-authentication to get new token.
          */
          if (!token) {
            await lockForTokenRefresh();
            await tokenRefreshMutex.waitForUnlock();
          }
          headers.set('ServiceWorker-Append-Token', 'true');
          headers.set('ServiceWorker-Authority', _oidcConfig.authority);
          headers.set('ServiceWorker-ClientId', _oidcConfig.client_id);
        } else {
          // for legacy oidc client, we need to append the token manually
          const token = await _getTokenFunc();
          headers.set('authorization', `Bearer ${token}`);
        }
        return headers;
      },
    });
    const result = Promise.resolve(bs(queryArgs, baseQueryApi, extraOptions));
    return result.then(payload => {
      // if (payload.error && isErrorResponseOfVsApi(payload.error)) {
      //   payload.error = transformErrorResponseOfVsApi(payload.error);
      // }
      return payload;
    });
  };
