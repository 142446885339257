import { Mutex, MutexInterface } from 'async-mutex';

export function isServiceWorkerAvailable() {
  return 'serviceWorker' in navigator;
}

export function isServiceWorkerActive() {
  return isServiceWorkerAvailable() && navigator.serviceWorker.controller;
}

let _tokenRefreshMutexReleasor: MutexInterface.Releaser | undefined;
const _onLockEventListeners: (() => void)[] = [];
export const tokenRefreshMutex = new Mutex();
export async function lockForTokenRefresh() {
  if (!_tokenRefreshMutexReleasor) {
    _tokenRefreshMutexReleasor = await tokenRefreshMutex.acquire();
    _onLockEventListeners.forEach(cb => cb());
  }
}
export function releaseLockForTokenRefresh() {
  if (_tokenRefreshMutexReleasor) {
    _tokenRefreshMutexReleasor();
    _tokenRefreshMutexReleasor = undefined;
  }
}
export function registerOnLockEventListener(callback: () => void) {
  _onLockEventListeners.push(callback);
}
export function removeOnLockEventListener(callback: () => void) {
  const targetIndex = _onLockEventListeners.findIndex(cb => cb === callback);
  if (targetIndex !== -1) {
    _onLockEventListeners.splice(targetIndex, 1);
  }
}
