export enum Action {
  Length = 'store.length',
  Key = 'store.key()',
  GetItem = 'store.getItem()',
  SetItem = 'store.setItem()',
  RemoveItem = 'store.removeItem()',
  Clear = 'store.clear()',
}

export type LengthType = number;
export type KeyType = string | null;
export type GetItemType = string | null;

export type SendMessageType = {
  (action: Action.Length): Promise<LengthType>;
  (action: Action.Key, payload: number): Promise<KeyType>;
  (action: Action.GetItem, payload: string): Promise<GetItemType>;
  (
    action: Action.SetItem,
    payload: { key: string; value: string }
  ): Promise<void>;
  (action: Action.RemoveItem, payload: string): Promise<void>;
  (action: Action.Clear): Promise<void>;
};
