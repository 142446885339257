import { AsyncStorage } from 'oidc-client-ts';

import { Action, type SendMessageType } from './types';

export class ClientStore implements AsyncStorage {
  private sendMessage: SendMessageType = async (
    action: Action,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload?: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> => {
    return new Promise(resolve => {
      try {
        const channel = new MessageChannel();
        channel.port1.onmessage = event => {
          resolve(event.data);
        };

        navigator.serviceWorker.ready.then(registration => {
          registration.active?.postMessage({ action, payload }, [
            channel.port2,
          ]);
        });
      } catch (error) {
        console.error('', error);
      }
    });
  };

  get length() {
    return this.sendMessage(Action.Length);
  }

  async key(index: number) {
    return this.sendMessage(Action.Key, index);
  }

  async getItem(key: string) {
    return this.sendMessage(Action.GetItem, key);
  }

  async setItem(key: string, value: string) {
    return this.sendMessage(Action.SetItem, { key, value });
  }

  async removeItem(key: string) {
    return this.sendMessage(Action.RemoveItem, key);
  }

  async clear() {
    return this.sendMessage(Action.Clear);
  }
}
